/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.part-detail {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  margin-bottom: 4px;
}
.part-detail:last-child {
  margin-bottom: 0;
}
.part-detail .change-icon {
  font-size: 18px;
  margin-right: 4px;
}
.part-detail .change-icon-action {
  font-size: 12px;
  line-height: 18px;
  vertical-align: middle;
  margin-right: -4px;
}
.part-detail .part-name {
  vertical-align: middle;
  font-size: 0.9em;
}
