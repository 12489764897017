/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.part-filter-pane {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 16px;
  align-items: center;
  justify-content: flex-end;
}
.part-filter-pane .filter-input {
  margin-right: 8px;
  margin-bottom: 8px;
}
